<template>
  <div class="text-container">
    <div>
      <p>
        Richten Sie mit uns gemeinsam Ihre Apotheke am Markt aus. Wir helfen
        Ihnen mit modernen Marketing Maßnahmen, die Möglichkeiten Ihres
        Standortes umfänglich zu nutzen. Für jeden soll die Kundenorientierung
        Ihrer Apotheke spürbar sein.
      </p>
      <div>Wir unterstützen Sie hierzu in den folgenden Bereichen:</div>
    </div>
    <div class="pt-2">
      <div
        v-for="service in getChildsOfSite('marketing')"
        :key="service.name"
        class="my-1"
      >
        <a :href="getSitePath(service.name)" class="service-link">
          <b-icon :icon="service.icon" class="mr-3"></b-icon>
          <span>{{ service.label.replace("[shy]", "") }}</span>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import { getChildsOfSite, getSitePath } from "../../config/site-structure";

export default {
  methods: { getChildsOfSite, getSitePath },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Richten Sie mit uns gemeinsam Ihre Apotheke am Markt aus. Wir helfen Ihnen mit modernen Marketing Maßnahmen, die Möglichkeiten Ihres Standortes umfänglich zu nutzen.",
      },
    ],
  },
};
</script>

<style scoped>
.service-link {
  color: #2c3e50;
}
</style>